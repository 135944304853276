<template>
  <div :class="['permission']">
    <template v-if="!disabled && allowed">
      <slot></slot>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Permission',
  data: () => ({
    allowed: false,
  }),
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: [Array, String],
      default: () => ([]),
    },
    relatedId: {
      type: [Array, String],
      default: () => ([]),
    },
    action: {
      type: [Array, String],
      default: () => ([]),
    },
  },
  computed: {
    ...mapState('user', {
      user: state => (state.user) || false
    }),
  },
  watch: {
    disabled () { this.checkPermission() },
    name () { this.checkPermission() },
    relatedId () { this.checkPermission() },
  },
  mounted () {
    setTimeout(() => {
      this.checkPermission()
    },100)
  },
  methods: {
    checkPermission () {
      if (!this.disabled && this.user && this.name) {
        if (this.user.admin) return this.allowed = true
        if (this.user.permissions && this.user.permissions.length > 0) {
          for(const permission of this.user.permissions) {
            let names = this.name
            if (typeof names === 'string') names = [this.name]
            if (names.indexOf(permission.name) !== -1) {
              if (!+permission.related_id && permission.action === 'all') return this.allowed = true
              let relatedIds = this.relatedId === "0" ? 0 : this.relatedIds
              let actions = this.action
              if (typeof relatedIds === 'string') relatedIds = [relatedIds ]
              if (typeof actions === 'string') actions = [actions]
              if (
                (!relatedIds || (relatedIds && (!+permission.related_id || relatedIds.indexOf(+permission.related_id) !== -1)))
                && (!actions || (actions && (permission.action === 'all' || actions.indexOf(permission.action) !== -1)))
              ) return this.allowed = true
            }
          }
        }
      }
      return this.allowed = false
    },
  }
}


</script>
<style>
.permission {
  display: inline;
}
</style>
