<template>
  <v-overlay
    color="#f0f0f0"
    opacity="1"
    :light="true"
    :dark="false"
    :value="show"
    z-index="99"
    :transition="false"
    class="form-stepper-overlay height-100 width-100"
  >
    <v-container fluid class="form-stepper-container pa-0">
      <v-row row class="height-100 ma-0">
        <v-col
          cols="4"
          lg="3"
          xl="3"
          class="theme--dark primary height-100 d-flex pa-0"
          style="min-width:200px;"
        >
          <div class="form-stepper-inner">
            <div class="form-stepper-header">
              <v-toolbar
                color="transparent"
                dark
                flat
                height="90px"
                class="pl-4"
              >
                <v-avatar>
                  <v-icon size="32" class="mr-4">{{ icon || 'mdi-plus' }}</v-icon>
                </v-avatar>
                <v-toolbar-title>
                  <span class="text-h6 mt-2">{{ subtitle }}</span>
                </v-toolbar-title>
                <v-spacer/>
                <!-- <v-btn
                  icon
                  class="back-btn ma-0 bordered"
                  color="white"
                  @click="$emit('close')"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn> -->
              </v-toolbar>
              <v-divider dark/>
            </div>
            <div class="form-stepper-content">
              <v-list dark class="primary form-stepper-progress pa-0">
                <v-list-item
                  v-for="(item,i) in steps"
                  :key="`prog${i}`"
                  :class="['align-start', currentStep === i ? 'form-stepper-progress-active' : '' ]"
                  @click="goToStep(i)"
                  :ripple="(typeof valids[i] !== 'undefined')"
                >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-chip
                      small
                      outlined
                      class="mr-2 pa-2 form-stepper-num"
                    >
                      {{ i+1 }}
                    </v-chip>
                    <small class="text-h6">{{ item.title }}</small>
                  </v-list-item-title>
                  <div
                    v-if="currentStep === i && item.description"
                    class="form-stepper-description mt-2 pa-2 pr-4 pl-4 white-alpha rounded"
                  >
                    <small>{{ item.description }}</small>
                  </div>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon
                    size="32"
                    v-if="(typeof valids[i] === 'undefined')"
                    color="white"
                  >
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                  <v-icon
                    size="32"
                    v-if="valids[i] === null"
                    color="info"
                  >
                    mdi-circle-slice-8
                  </v-icon>
                  <v-icon
                    size="32"
                    v-if="valids[i] === false"
                     color="error"
                    >
                      mdi-alert-circle
                    </v-icon>
                  <v-icon
                    size="32"
                    v-if="valids[i] === true"
                    color="success"
                  >
                    mdi-check-circle
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
        </div>
      </v-col>
        <v-col
          cols="8"
          lg="9"
          xl="8"
          class="primary--text height-100 d-flex pa-0"
        >
          <!-- <button @click="currentStep++">+</button>
          <button @click="currentStep--">-</button> -->
          <template v-for="(item,i) in steps">
            <div
              class="form-stepper-inner"
              v-if="currentStep === i"
              :key="'step' + i"
            >
              <div class="form-stepper-header">
                <v-toolbar
                  color="transparent"
                  light
                  flat
                  height="90px"
                >
                  <v-btn
                    :disabled="currentStep === 0"
                    icon
                    class="mr-4 ml-4"
                    @click="goToStep(currentStep - 1)"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <div class="primary--text">
                    <span class="text-h5">{{ item.title }}</span>
                  </div>
                  <v-spacer/>
                  <v-btn
                    icon
                    @click="$emit('close')"
                    class="mr-4"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <div class="toolbar-separator primary ml-4 mr-4"/>

              </div>
              <div class="form-stepper-content" :key="updateKey">
                <component
                  v-if="componentsAsync[i]"
                  :is="componentsAsync[i]"
                  :values="values"
                  :items="value"
                  @close="close"
                  @next="nextStep"
                  @step="goToStep"
                  @update="v => $emit('update', v)"
                  @create="v => $emit('create', v)"
                  @childData="handleChildData"
                  @validation="validForm"
                />
              </div>
            </div>
          </template>
        </v-col>
        <!-- <v-col
          cols="1"
          lg="2"
          xl="2"
          class="d-none primary--text height-100 d-flex align-center"
        /></v-col> -->
      </v-row>
    </v-container>
  </v-overlay>
</template>

<script>
const _componentsCache = new Map()

const _stepItems = [
  {
    title: 'Step 1',
    description: 'Step 1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    required: false,
    component: 'formStepper/FormStepperJob/FormStepperJobTitle'
  },
  {
    title: 'Step 2',
    description: 'Step 2 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    required: false,
    component: 'formStepper/FormStepperJob/FormStepperJobSalary'
  },
  {
    title: 'Final step',
    required: true,
    component: 'formStepper/FormStepperJob/FormStepperJobFinal'
  },
]

export default {
  name: 'Stepper',
  data: () => ({
    componentsAsync: [],
    // steps: _stepItems,
    valids: [],
    values: {},
    currentStep: 0,
    currentStepItem: null,
    updateKey: 0,
  }),
  props: {
    subtitle: {
      type: String,
      default: 'JOB',
    },
    steps: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: "mdi-plus",
    },
    value: {
      type: Object,
      default: () => ({})
    },
    show: {
      type: [Boolean, String],
      default: false
    }
  },
  watch: {
    value(n){
      this.init()
    },
    currentStep (n) {
      this.currentStepItem = this.steps[n]
    }
  },
  mounted () {
    if (this.value) this.init()
      this.initSteps()
  },
  methods: {

    init() {
      const values = {}
      Object.keys(this.value).forEach((key) => {
        if (this.value[key]) values[key] = this.value[key]
      })
      Object.assign(this.values,values)
    },

    close () {
      this.values = {}
      this.$emit('close')
    },

    validForm (v) {
      this.valids[this.currentStep] = v
    },

    nextStep () {
      if (this.currentStep === this.steps.length) {
        this.$emit('input', this.values)
      } else {
        this.currentStep++
      }
    },

    initSteps () {
      this.valids = new Array(this.steps.length).fill(undefined)
      this.currentStepItem = 0
      this.steps.forEach((step, i) => {
        this.loadComponent(step.component, i)
      })
    },

    loadComponent (component, i) {
      if (component) {
        if (!_componentsCache.has(component)) {
          import(`../${component}.vue`)
            .then(component => {
              _componentsCache.set(component, component.default)
              this.componentsAsync[i] = component.default
            })
            .finally(() => {
              this.updateKey++
            })
        } else {
          this.componentsAsync[i] = _componentsCache.get(component) || null
        }
      }
    },

    handleChildData (data) {
      data = this.$clone(data)
      Object.keys(data)
        .forEach((key, i) => {
          if (typeof data[key] !== 'undefined') this.values[key] = data[key]
        })
    },

    goToStep (i) {
      if (typeof this.valids[i] !== 'undefined') {
        if (i > -1) {
          this.currentStep = i
        } else {
          this.currentStep = 0
        }
      }
    },
  }
}
</script>

<style>

.form-stepper-overlay {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.form-stepper-overlay > .v-overlay__content {
  width: 100%;
}

.form-stepper-container {
  width: 100vw;
  height: 100vh;
}

.form-stepper-num {
  margin-bottom: 5px;
}

.form-stepper-content {
  flex-grow: 1;
}

.form-stepper-content .v-input fieldset {
  background: #fff;
}

.form-stepper-content .form-footer {
  flex-grow: 0;
}

.form-stepper-overlay .v-toolbar__content {
  padding: 0;
}

.form-stepper-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-stepper-fixed-witdh {
  min-width: 220px;
  max-width: 220px;
}

.form-stepper-progress .v-list-item__action {
  align-self: flex-start;
}

.form-stepper-progress .form-stepper-description {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transform: translateY(-50px);
  transition: all 350ms;
}

@keyframes showDescription {
  0% {
    opacity: 0;
    height: 0;
    transform: translateY(50px);
  }

  50% {
    opacity: 0;
    height: 30px;
  }

  to {
    height: auto;
    transform: translateY(0);
    opacity: 1;
  }
}

.form-stepper-progress .v-list-item {
  opacity: 0.3;
  transition: all 350ms;
}

.form-stepper-progress .v-list-item.form-stepper-progress-active {
  opacity: 1;
}

.form-stepper-progress-active .form-stepper-description {
  animation: showDescription 350ms;
  transform: translateY(0);
  opacity: 1;
  height: auto;
}

.form-stepper {
  position: relative;
  display: flex;
  flex-direction: column;
  /* min-height: 70vh;
  max-height: 70vh; */
  height: 100%;
}

.form-stepper form {
  height: 1px;
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}

</style>
