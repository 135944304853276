<template>
  <div class="chart" ref="container">
    <Loading :value="loading" :loading-color="loadingColor" />
    <div class="chart-container piechart" ref="piechart" />
    <div class="chart-legend">
      <div v-for="(item, i) in values" :key="i" class="text-ellipsis">
        <v-icon :color="item.color" size="16">mdi-circle</v-icon>
        <small class="ml-1">{{item.label}}</small>
      </div>
    </div>
  </div>
</template>
<script>
import * as d3 from "d3";
import Loading from "@/components/Loading";

export default {
  name: "PieChart",
  components: {
    Loading,
  },
  props: {
    values: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingColor: {
      type: String,
      default: "white",
    },
    margin: {
      type: Object,
      default: () => ({ top: 0, right: 0, bottom: 0, left: 100 }),
    }
  },
  data: () => ({
    svg: null,
    width: 400,
    height: 400,
    radius: 200,
    tooltip: null,
    line: null,
    area: null,
    hoveredValue: null,
  }),
  mounted() {
    this.screensize();
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      if (this.changeScreesizeEvent) clearTimeout(this.changeScreesizeEvent);
      this.changeScreesizeEvent = setTimeout(() => {
        if (window.clientWidth !== this.width) this.init();
      }, 80);
    },

    screensize() {
      window.addEventListener("resize", this.resize);
    },

    init() {
      const chart = this.$refs.piechart;
      if (chart) this.$refs.piechart.innerHTML = "";

      const container = this.$refs.container;
      if (container) {
        this.width = container.clientWidth - this.margin.left - this.margin.right
        this.height = container.clientHeight - this.margin.top - this.margin.bottom
      }

      if (this.width < 200) this.width = 200 - this.margin.left - this.margin.right
      if (this.height < 200) this.height = 200 - this.margin.top - this.margin.bottom
      this.radius = Math.min(this.width, this.height) / 2.2

      // X axis
      this.draw()
    },

    draw() {
      var svg = d3
        .create("svg")
        .attr("width", this.width + this.margin.left + this.margin.right)
        .attr("height", this.height + this.margin.top + this.margin.bottom);

      var g = svg
        .append("g")
        .attr("transform", `translate(${(this.width / 2) + this.margin.left},${(this.height / 2)  + this.margin.top})`);

      var arc = d3.arc().innerRadius(this.radius / 1.5).outerRadius(this.radius);

      var pie = d3.pie().value((d) => d.value);

      var div = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip-donut")
        .style("opacity", 1);

      var currentData = this.values;
      let totalValue = currentData.reduce((a, b) => a + b.value, 0)
      var arcs = g
        .selectAll("arc")
        .data(pie(this.values))
        .enter()
        .append("g")
        .style("cursor", "pointer")
        .on("mouseover", function (d, i, f) {
          d3.select(this).transition().duration("200").attr("opacity", ".50")

          div.transition().duration(50).style("opacity", 1)

          let percent = Math.round((i.value * 100) / totalValue)

          let num = ''
          num += '<div style="padding: 5px 12px; border: solid 2px ' + i.data.color + '; border-radius: inherit;">'
            num += '<div class="pa-4 pb-2"><small>' + i.data.label + '</small></div>'
            num += '<strong class="pa-4 pt-2 numeric">'
              num += percent < 1
              ? ((percent*100/10) / totalValue).toFixed(1)
              : percent.toString()
              num += '%'
            num += '</strong>'
          num += '</div>'

          div
            .html(num)
            .style("left", `${d.pageX + 10}px`)
            .style("top", `${d.pageY - 15}px`);
        })
        .on("mouseout", function (d, i) {
          d3.select(this).transition().duration("200").attr("opacity", "1");
          div.transition().duration("50").style("opacity", 0);
        })
        .on("click", function (d, i) {
          d3.select(this)
            .transition()
            .duration("200")
            .attr("opacity", ".3")
            .on("end", function () {
              d3.select(this)
                .transition()
                .duration("200")
                .attr("opacity", ".5");
            });

          //here put emit signal when needed
        });

      // if (svg.node().getAttribute("width") > 450) {
      //hide legend if not enough space
      var color = d3
        .scaleOrdinal()
        .domain(this.values.map((d) => d.color))
        .range(this.values.map((d) => d.color));

      // svg
      //   .selectAll("mydots")
      //   .data(this.values.map((d) => d.label))
      //   .enter()
      //   .append("circle")
      //   .attr("cx", 50)
      //   .attr("cy", function (d, i) {
      //     return 10 + i * 25;
      //   }) // 100 is where the first dot appears. 25 is the distance between dots
      //   .attr("r", 8)
      //   .style("fill", function (d) {
      //     return color(d);
      //   });
      //
      // svg
      //   .selectAll("mylabels")
      //   .data(this.values.map((d) => d.label))
      //   .enter()
      //   .append("text")
      //   .attr("x", 70)
      //   .attr("y", function (d, i) {
      //     return 10 + i * 25;
      //   })
      //   // .style("fill", function (d) {
      //   //   return color(d);
      //   // })
      //   .style("fill", "black")
      //   .style("font-size", 14)
      //   .text(function (d) {
      //     return d;
      //   })
      //   .attr("text-anchor", "left")
      //   .style("alignment-baseline", "middle");
      // }
      arcs
        .append("path")
        .attr("fill", (data, i) => {
          let pieSection = data.data;

          return pieSection.color; //use color provided by data
          // return d3.schemeSet3[i]; use color provided by D3
        })
        .attr("d", arc);

      this.$refs.piechart.append(svg.node());
    },
  },
};
</script>

<style>
.piechart path {
  stroke-width: 2px;
  stroke: white;
}
.chart {
  position: relative;
  width:100%;
  height: 100%;
}
.chart-legend {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
}

.tooltip-donut {
  position: absolute;
  text-align: center;
  /* padding: 0.5rem; */
  background: #ffffff;
  color: #313639;
  /* border: 1px solid #313639; */
  border-radius: 8px;
  pointer-events: none;
  font-size: 1rem;
  min-width: 80px;
  min-height: 40px;
}
</style>
