<template>
  <Stepper
    :subtitle="$tc('CHANNEL_SUBSCRIPTION.TITLE', selected.length).toUpperCase()"
    :steps="steps"
    :show="show"
    :icon="$t('CHANNEL_SUBSCRIPTION.ICON')"
    @close="v => $emit('close', v)"
    @create="v => $emit('create', v)"
  />
</template>
<script>
import Stepper from '@/components/Stepper'

export default {
  name: "FormChannelSubscriptionStepper",
  components: {
    Stepper
  },
  data: (_this) => ({
    steps: [
      {required: true, title: _this.$i18n.t('CHANNEL_SUBSCRIPTION.STEP_CHANNEL_TITLE'), description: _this.$i18n.t('CHANNEL_SUBSCRIPTION.STEP_CHANNEL_DESCRIPTION'), component: 'forms/channel_subscription/stepper/FormChannelSubscriptionStepper.channel'},
      {required: true, title: _this.$i18n.t('CHANNEL_SUBSCRIPTION.STEP_PARAMS_TITLE'), description: _this.$i18n.t('CHANNEL_SUBSCRIPTION.STEP_PARAMS_DESCRIPTION'), component: 'forms/channel_subscription/stepper/FormChannelSubscriptionStepper.params'},
      // {required: true, title: _this.$i18n.t('CHANNEL_SUBSCRIPTION.STEP_CONTRACT_TITLE'), description: _this.$i18n.t('CHANNEL_SUBSCRIPTION.STEP_CONTRACT_DESCRIPTION'), component: 'forms/channel_subscription/stepper/FormChannelSubscriptionStepper.contract'},
      {required: true, title: _this.$i18n.t('CHANNEL_SUBSCRIPTION.STEP_CONFIRM_TITLE'), description: _this.$i18n.t('CHANNEL_SUBSCRIPTION.STEP_CONFIRM_DESCRIPTION'), component: 'forms/channel_subscription/stepper/FormChannelSubscriptionStepper.confirm'},
    ],
  }),
  props: {
    show: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => ([])
    }
  }
}

</script>
