<template>
  <v-form ref="form" v-model="valid">
    <v-card max-height="100vh">
      <Loading :value="loading" />

      <div>

        <v-system-bar dark color="primary">
          <v-icon class="mr-1">mdi-pencil</v-icon>
          <template v-if="id">
            <small><strong class="text-uppercase">{{ $t('CHANNEL_SUBSCRIPTION_CONTRACT.FORM_TITLE_UPDATE') }}</strong></small>
            <v-icon class="ml-4" size="12">mdi-pound</v-icon>
            <small>{{ id }}</small>
          </template>
          <small v-else><strong class="text-uppercase">{{ $t('CHANNEL_SUBSCRIPTION_CONTRACT.FORM_TITLE_CREATE') }}</strong></small>
          <v-spacer/>
          <v-icon small class="clickable" @click="closeDialog">
            mdi-close
          </v-icon>
        </v-system-bar>

        <v-toolbar color="transparent" dense flat height="60px">
          <v-toolbar-title>{{$t('CHANNEL_SUBSCRIPTION_CONTRACT.TITLE') + ': ' + this.currentChannel.title}}</v-toolbar-title>
          <v-spacer />
          <v-btn
            x-small
            fab
            depressed
            color="error"
            class="mr-4"
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            rounded
            depressed
            color="success"
            :disabled="!valid"
            @click="apply"
          >
            <v-icon class="mr-2">mdi-check</v-icon>
            {{$t('COMMON.APPLY')}}
          </v-btn>
        </v-toolbar>

        <div class="toolbar-separator primary ml-4 mr-4"/>
      </div>

      <div
        ref="content"
        class="pa-6 pt-0 scroll"
        style="max-height: calc(80vh - 61px)"
      >

        <Loading :value="loading" />

        <div class="pa-0 mt-4">
          <div class="pb-4" v-if="id && contract.commited + contract.used !== 0">
            <p class="primary--text">Usage</p>
            <v-chip small label color="grey" class="mr-2">Commited : {{contract.commited}}</v-chip>
            <v-chip small label color="green">Used : {{contract.used}}</v-chip>
          </div>

          <p class="primary--text mb-0">{{$t('CHANNEL_SUBSCRIPTION_CONTRACT.AMOUNT')}}</p>
          <p class="grey--text mb-0"><small>{{$t('CHANNEL_SUBSCRIPTION_CONTRACT.AMOUNT_TEXT')}}</small></p>

          <v-row class="mt-0">
            <v-col cols="8" class="pt-0">
              <v-switch
                dense
                disabled
                v-model="contract.no_limit_value"
                :label="$t('CHANNEL_SUBSCRIPTION_CONTRACT.NO_LIMIT_VALUE')"
                :placeholder="$t('CHANNEL_SUBSCRIPTION_CONTRACT.NO_LIMIT_VALUE')"
                outlined
              />
            </v-col>
          </v-row>

          <!--
          <v-row class="mt-0">
            <v-col cols="3" class="pb-0 pt-0">
              <v-select
                dense
                v-model="contract.type"
                :disabled="contract.no_limit_value"
                :items="contractTypeItems"
                :label="$t('INPUT.TYPE')"
                :placeholder="$t('INPUT.TYPE')"
                :rules="contract.no_limit_value ? [true] : [$rules.required]"
                required
                outlined
              />
            </v-col>
            <v-col cols="1" class="pb-0 pt-0" />

            <template v-if="contract.type === 'budget'">
              <v-col cols="4" class="pb-0 pt-0">
                <v-text-field
                  dense
                  v-model="contract.value"
                  type="number"
                  :min="contract.commited"
                  :rules="contract.no_limit_value ? [true] : [$rules.required, contract.used > 0 ? $rules.min(contract.used) : true]"
                  :disabled="contract.no_limit_value"
                  :label="$t('INPUT.BUDGET_ALLOCATED')"
                  :placeholder="$t('INPUT.BUDGET_ALLOCATED')"
                  :append-icon="$t('COMMON.LOCALE_CURRENCY_SIGN')"
                  outlined
                />
              </v-col>
              <v-col cols="4" class="pb-0 pt-2" v-if="contract.used > 0">
                <small class="grey--text">{{ $tc('CHANNEL_SUBSCRIPTION_CONTRACT.ALREADY_COMMITED_COUNT', contract.commited) }}</small>
              </v-col>
            </template>

            <template v-else-if="contract.type === 'credit'">
              <v-col cols="4" class="pb-0 pt-0">
                <v-text-field
                  dense
                  v-model="contract.value"
                  type="number"
                  :min="contract.commited"
                  :rules="contract.no_limit_value ? [true] : [$rules.required, contract.commited > 0 ? $rules.min(contract.commited) : true]"
                  :disabled="contract.no_limit_value"
                  :label="$t('INPUT.CREDIT')"
                  :placeholder="$t('INPUT.CREDIT')"
                  append-icon="Cr"
                  outlined
                />
              </v-col>
              <v-col cols="4" class="pb-0 pt-0">
                <v-text-field
                  dense
                  v-model="contract.price_total"
                  type="number"
                  :disabled="contract.no_limit_value"
                  :label="$t('INPUT.TOTAL_BUDGET')"
                  :placeholder="$t('INPUT.TOTAL_BUDGET')"
                  :append-icon="$t('COMMON.LOCALE_CURRENCY_SIGN')"
                  outlined
                />
              </v-col>
            </template>
          </v-row>
        -->

        </div>

        <div class="pa-0 mt-6">
          <p class="primary--text mb-0">{{$t('CHANNEL_SUBSCRIPTION_CONTRACT.VALIDITY')}}</p>
          <p class="grey--text mb-0"><small>{{$t('CHANNEL_SUBSCRIPTION_CONTRACT.VALIDITY_TEXT')}}</small></p>
          <v-row class="mt-0">
            <v-col cols="8" class="pt-0 pb-0">
              <v-switch
                dense
                v-model="contract.no_limit_time"
                :label="$t('CHANNEL_SUBSCRIPTION_CONTRACT.NO_LIMIT_TIME')"
                :placeholder="$t('CHANNEL_SUBSCRIPTION_CONTRACT.NO_LIMIT_TIME')"
                outlined
              />
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="3" class="pb-0">
              <v-select
                dense
                v-model="contract.validity"
                :disabled="contract.no_limit_time"
                :items="['dates', 'duration']"
                :label="$t('INPUT.TYPE')"
                :placeholder="$t('INPUT.TYPE')"
                :rules="contract.no_limit_time ? [true] : [$rules.required]"
                required
                outlined
              />
            </v-col>
            <v-col cols="1" class="pb-0" />
            <template v-if="contract.validity === 'dates'">
              <v-col cols="4" class="pb-0">
                <v-menu
                  ref="menu_start_date"

                  :close-on-content-click="false"
                  transition="none"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      v-model="contract.start_date"
                      :disabled="contract.no_limit_time"
                      :label="$t('INPUT.DATE_START')"
                      prepend-inner-icon="mdi-calendar"
                      :rules="contract.no_limit_time ? [true] : [$rules.required]"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    />
                  </template>
                  <v-date-picker
                    ref="picker"
                    no-title
                    v-model="contract.start_date"
                    :min="new Date().toISOString().substr(0, 10)"
                    @change="save_start_date"
                  />
                </v-menu>
              </v-col>
              <v-col cols="4" class="pb-0">
                <v-menu
                  ref="menu_end_date"

                  :close-on-content-click="false"
                  transition="none"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      v-model="contract.end_date"
                      :disabled="contract.no_limit_time"
                      :label="$t('INPUT.DATE_END')"
                      prepend-inner-icon="mdi-calendar"
                      :rules="contract.no_limit_time ? [true] : [$rules.required]"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    />
                  </template>
                  <v-date-picker
                    ref="picker"
                    no-title
                    v-model="contract.end_date"
                    :min="contract.start_date"
                    @change="save_end_date"
                  />
                </v-menu>
              </v-col>
            </template>
            <template v-else-if="contract.validity === 'duration'">
              <v-col cols="2" class="pb-0">
                <v-text-field
                  dense
                  v-model="contract.duration"
                  :disabled="contract.no_limit_time"
                  :rules="contract.no_limit_time ? [true] : [$rules.required]"
                  min="0"
                  placeholder="0"
                  type="number"
                  outlined
                />
              </v-col>
              <v-col cols="6" class="pb-0 pt-4 grey--text pl-0">{{$t('CHANNEL_SUBSCRIPTION_CONTRACT.VALIDITY_DAYS_AFTER')}}</v-col>
            </template>
          </v-row>
        </div>
      </div>
    </v-card>
  </v-form>

</template>

<script>

import Loading from '@/components/Loading'

export default {
  name:"FormChannelSubscriptionCompactContractsForm",
  components: {
    Loading
  },
  data: () => ({
    dialog: false,
    valid: false,
    loading: false,
    menu_start_date: new Date().toISOString().slice(0,10),
    menu_end_date: undefined,
    contract_default_value: {
      no_limit_value: true,
      type: undefined,
      value: undefined,
      price_total: undefined,
      no_limit_time: true,
      validity: 'dates',
      start_date: undefined,
      end_date: undefined,
      duration: undefined,
    },
    contract: {
      id: undefined,
      no_limit_value: true,
      type: undefined,
      value: undefined,
      price_total: undefined,
      no_limit_time: true,
      validity: undefined,
      start_date: undefined,
      end_date: undefined,
      duration: undefined,
    },
  }),
  computed: {
    currentChannel () {
      if (this.props.channel) return this.props.channel
      if (this.values.channel) return this.values.channel
      return {}
    },
    contractTypeItems () {
      if (this.props.channel && this.props.channel.contract_types) return this.props.channel.contract_types
      if (this.values.channel && this.values.channel.contract_types) return this.values.channel.contract_types
      return []
    }
  },
  props: {
    props: {
      type: [Object, undefined],
      default: () => ({})
    },
    values: {
      type: [Object, undefined],
      default: () => ({})
    },
    loaded_values: {
      type: [Object, undefined],
      default: () => ({})
    },
    id: {
      type: [String, undefined],
      default: undefined
    },
  },
  watch: {
    props: {
      handler () {
        this.init()
      },
      deep: true,
    },
    values: {
      handler () {
        this.init()
      },
      deep: true,
    },
    id (n) {
      this.init(n)
    }
  },
  created () {
    this.init(this.id)
  },
  methods: {

    init (id) {
      if (id) {
        this.loadContract()
      } else {
        this.$nextTick(() => {
          this.resetContract()
        })
      }
    },

    resetContract() {
      const contract = this.$clone(this.contract_default_value)
      contract.type = 'budget'
      contract.validity = 'dates'

      if (
        this.props
        && this.props.channel_subscription
        && this.props.channel_subscription.id
      ) contract.channel_subscription_id = this.props.channel_subscription.id

      if (
        this.props
        && this.props.channel
        && this.props.channel.type
        && this.props.channel.type.length > 0
      ) contract.type = this.props.channel.types[0]

      if (
        this.values
        && this.values.channel
        && this.values.channel.type
        && this.values.channel.type.length > 0
      ) contract.type = this.values.channel.types[0]

      this.contract = contract

    },

    removeItem(contract_id) {
      this.$services.api_programmatic.channel_subscription_contract
        .remove({ where: { id: contract_id || this.id } })
        .then((response) => {
          this.dialog = false
        })
    },

    apply() {
      if (this.$refs.form.validate()) {
        if (this.id) {
          this.updateContract()
        } else {
          this.createContract()
        }
      }
    },

    createContract() {
      // prepare values
      const values = {
        channel_subscription_id: this.contract.channel_subscription_id || this.loaded_values.id,
        no_limit_value: this.contract.no_limit_value,
        no_limit_time: this.contract.no_limit_time,
        type: this.contract.type,
        status: this.contract.status,
        start_date: this.contract.start_date || undefined,
        end_date: this.contract.end_date || undefined,
        price_total: this.contract.price_total,
        value: this.contract.value,
      }
      this.loading = true
      return this.$services.api_programmatic.channel_subscription_contract
        .create({values})
        .then(response => {
          if (response.data) {
            this.$emit('update', response.data.id)
            this.loadContract()
          }
        })
        .catch((response) => {
          this.$store.dispatch('messenger/add', {
            type: 'error',
            code: response.code,
            message: response.message,
            time: 5000,
          })
        })
        .finally(() => {
          this.loading = false
        })
    },

    updateContract() {
      this.loading = true
      return this.$services.api_programmatic.channel_subscription_contract
        .update({
          values: {
            channel_subscription_id: this.contract.channel_subscription_id || this.loaded_values.id,
            no_limit_value: this.contract.no_limit_value,
            no_limit_time: this.contract.no_limit_time,
            type: this.contract.type,
            status: this.contract.status,
            start_date: this.contract.start_date || undefined,
            end_date: this.contract.end_date || undefined,
            price_total: this.contract.price_total,
            value: this.contract.value,
          },
          where: {id: this.id},
        })
        .then(response => {
          if (response.data && response.data.length > 0) {
            this.$emit('update')
            // this.contract = response.data[0]
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    loadContract() {
      this.loading = true
      return this.$services.api_programmatic.channel_subscription_contract
        .search({
          where: {id: this.id},
          options: {limit: 1}
        })
        .then(response => {
          if (response.data && response.data.length > 0) {
            this.contract = response.data[0]
            if (!this.contract.no_limit_time) {
              if (this.contract.start_date) {
                this.contract.validity = 'dates'
              } else {
                this.contract.validity = 'duration'
              }
            }
          }
        })
        .finally(() => {
          this.loading = false
        })

    },

    closeDialog () {
      this.$emit('close')
    },

    save_start_date(date) {
      this.$refs.menu_start_date.save(date)
    },

    save_end_date(date) {
      this.$refs.menu_end_date.save(date)
    },



  }
}
</script>
