<template>
  <main class="page-main">
    <!-- <v-container align-start fill-height class=" pt-0 pb-0 flex-column flex-nowrap"> -->
      <div :class="['page-main-inner', typeof row !== 'undefined' ? 'row' : '', typeof column !== 'undefined' ? 'column' : '']">
        <slot/>
      </div>
    <!-- </v-container> -->
  </main>
</template>

<style>

.page-main {
  background: #f0f0f0;
  flex-grow: 1;
  height: 100px; /*hack*/
  position: relative;
}

.page-main-inner {
  /* background: #fff; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.page-main-inner.column {
  flex-direction: column;
}
.page-main-inner.row {
  flex-direction: row;
}

.page-main > .v-container {
  background: #fff;
}

</style>

<script>
export default {
  name: 'PageMain',
  props: ['row', 'column']
}
</script>
